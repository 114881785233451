import React, { useEffect, useRef, useState } from 'react';

import { LogoTM, Share } from './iconSvg';
import ModalShare from './ModalShare';
// import axios from 'axios';

const Header = ({ urlBanner, urlBannerMovil }) => {
    const ref = useRef(null);
    const [urlShort] = useState(window.location.href);
    const [showShare, setShowShare] = useState(false);
    const handleShare = async () => {
        // const { status, data } = await axios.post(`Configuracion/AcortarUrl`, {
        //     url: window.location.href
        // });
        setShowShare(true);
    };
    useEffect(() => {
        document.addEventListener('click', () => {
            if (ref.current) {
                ref.current.play();
            }
        })

        document.body.click()
    }, [])
    return (
        <header className="ld--header ">
            <div className="fixed-top">
                <div className="container-custom-tm">
                    <a href="https://ticketmundo.com.ve/">
                        <LogoTM />
                    </a>
                </div>
            </div>
            <figure className="urlBanner">
                {/* <video ref={ref} src={urlBanner} width={"100%"} loop={true} muted /> */}
                <img src={urlBanner} alt="" />
            </figure>
            <figure className="urlBannerMovil">
                <img src={urlBannerMovil} alt="" />
            </figure>
            <div className="content-bottom">
                <div className="container-custom-tm">
                    <div className="wrap-button">
                        <button className="share" onClick={handleShare}>
                            <Share />
                        </button>
                    </div>
                </div>
            </div>
            <ModalShare
                linkFunc={urlShort}
                show={showShare}
                handleClose={() => {
                    setShowShare(false);
                }}
            />
        </header>
    );
};

export default Header;
