import React from 'react';
import { Instagram } from './iconSvg';

const Productoras = () => {
    return (
        <section className="ld--wrap-prods">
            <div className="ld--card">
                <h5>Gaiteros</h5>
                <ul style={{ marginTop: '12px', maxHeight: `186px`, overflow: 'auto' }} className='px-0'>
                    <li className="produtore_list">
                        <div style={{ borderRadius: "50%", overflow: "hidden" }}>
                            <img src={`/images/ins-elpenon.jpg`} alt="gaitaspenon24" />
                        </div>
                        <div>
                            <span className="span_productor">El Peñon</span>
                            <a href="https://www.instagram.com/gaitaspenon24/" rel="noreferrer" target="_blank" style={{ color: `var(--color__text)`, cursor: 'pointer' }}>
                                <Instagram />
                                @gaitaspenon24
                            </a>
                        </div>
                    </li>
                    <li className="produtore_list">
                        <div style={{ borderRadius: "50%", overflow: "hidden" }}>
                            <img src={`/images/logo-yale.png`} alt="@gaitasyale" />
                        </div>
                        <div>
                            <span className="span_productor">Colegio Yale</span>
                            <a href="https://www.instagram.com/gaitasyale_/" rel="noreferrer" target="_blank" style={{ color: `var(--color__text)`, cursor: 'pointer' }}>
                                <Instagram />
                                @gaitasyale_
                            </a>
                        </div>
                    </li>
                    <li className="produtore_list">
                        <div style={{ borderRadius: "50%", overflow: "hidden" }}>
                            <img src={`/images/logo-la-salle.png`} alt="@gaitaslasalle24" />
                        </div>
                        <div>
                            <span className="span_productor">Colegio La Salle de la Colina.</span>
                            <a href="https://www.instagram.com/gaitaslasalle24/" rel="noreferrer" target="_blank" style={{ color: `var(--color__text)`, cursor: 'pointer' }}>
                                <Instagram />
                                @gaitaslasalle24
                            </a>
                        </div>
                    </li>
                    <li className="produtore_list">
                        <div style={{ borderRadius: "50%", overflow: "hidden" }}>
                            <img src={`/images/beatles-gaitas-ig.png`} alt="@gaitassrl24 " />
                        </div>
                        <div>
                            <span className="span_productor">Colegio Santa Rosa de Lima</span>
                            <a href="https://www.instagram.com/gaitassrl24/" rel="noreferrer" target="_blank" style={{ color: `var(--color__text)`, cursor: 'pointer' }}>
                                <Instagram />
                                @gaitassrl24
                            </a>
                        </div>
                    </li>
                    <li className="produtore_list">
                        <div style={{ borderRadius: "50%", overflow: "hidden" }}>
                            <img src={`/images/san-ignacio-ig.png`} alt="@gaitasanignacio " />
                        </div>
                        <div>
                            <span className="span_productor">Colegio San Ignacio de Loyola</span>
                            <a href="https://www.instagram.com/gaitasanignacio/" rel="noreferrer" target="_blank" style={{ color: `var(--color__text)`, cursor: 'pointer' }}>
                                <Instagram />
                                @gaitasanignacio
                            </a>
                        </div>
                    </li>
                    <li className="produtore_list">
                        <div style={{ borderRadius: "50%", overflow: "hidden" }}>
                            <img src={`/images/logo-emil-friedman.png`} alt="@gaitasfriedman2024 " />
                        </div>
                        <div>
                            <span className="span_productor">Colegio Emil Friedman</span>
                            <a href="https://www.instagram.com/gaitasfriedman2024/" rel="noreferrer" target="_blank" style={{ color: `var(--color__text)`, cursor: 'pointer' }}>
                                <Instagram />
                                @gaitasfriedman2024
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default Productoras;
